import React, { useContext, createContext } from "react";
import cookie from "js-cookie";
import queryString from "query-string";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import cookies from "next-cookies";
import axios, { AxiosError } from "axios";
import pickBy from "lodash/pickBy";
import production from "../.firebase/production.json";
import development from "../.firebase/development.json";
import { getBackendUrl } from "./env";
import logger from "./logger";
import { toQueryString } from "./stringUtils.mjs";
import PropTypes from "prop-types";

const PROD = "production";
const LOCAL = "local";
const DEV = "development";
const PREVIEW = "preview";

const ENVIRONMENT =
  process.env.NEXT_PUBLIC_VERCEL_ENV === PROD
    ? PROD
    : process.env.NEXT_PUBLIC_VERCEL_ENV === PREVIEW
    ? PREVIEW
    : process.env.NEXT_PUBLIC_USE_AUTH_EMULATOR
    ? LOCAL
    : DEV;

export function initFirebase() {
  if (!firebase.apps.length) {
    if (ENVIRONMENT === LOCAL || ENVIRONMENT === DEV) {
      console.log(
        "using firebase staging environment: " + development.authDomain
      );
    }
    firebase.initializeApp(
      ENVIRONMENT === PROD || ENVIRONMENT === PREVIEW ? production : development
    );
    // If env var is set, use auth emulator
    if (process.env.NEXT_PUBLIC_USE_AUTH_EMULATOR) {
      console.log("auth emulator setup");
      firebase.auth().useEmulator("http://localhost:5002");
    }
  }
}

initFirebase();

// Helper function to get firebaseToken from request headers.
export function getTokenFromCtx(ctx) {
  let firebaseToken;
  if (ctx && ctx.req) {
    // Get cookies server side
    firebaseToken = cookies(ctx).orchidAuth;
  } else {
    // Get cookies client side
    firebaseToken = cookie.get("orchidAuth");
  }
  return firebaseToken;
}

export async function silentContextAuthorizedCall(
  context,
  method,
  url,
  data,
  def
) {
  let result = def;
  try {
    result = await contextAuthorizedCall(context, method, url, data);
  } catch (e) {
    console.log(e);
  }
  return result;
}

export async function contextAuthorizedCall(context, method, url, data) {
  const firebaseToken = getTokenFromCtx(context);
  if (firebaseToken) {
    const response = await axios({
      method,
      url: getBackendUrl(url),
      headers: {
        Authorization: `Bearer ${firebaseToken}`,
      },
      data,
    });
    return response.data;
  }
  throw new AxiosError("No token in context", null, null, null, {
    status: 401,
  });
}

// // Redirects if the user isn't confirmed
// function handleUnverifiedEmail(ctx, nextUrl = null) {
//   if (!nextUrl) {
//     if (ctx && ctx.req && ctx.req.url) {
//       nextUrl = ctx.req.url;
//     } else {
//       nextUrl = window.location.pathname;
//     }
//   }
//   if (ctx && ctx.res && ctx.res.writeHeader) {
//     ctx.res.writeHeader(302, {
//       Location: `/verify-email?next=${encodeURI(nextUrl)}`,
//     });
//     ctx.res.end();
//   } else {
//     window.location.replace(nextUrl);
//   }
// }

// function handleForbidden(ctx, data, nextUrl = null) {
//   if (!nextUrl) {
//     if (ctx && ctx.req && ctx.req.url) {
//       nextUrl = ctx.req.url;
//     } else {
//       nextUrl = window.location.pathname;
//     }
//   }
//   const error = data?.error;
//   if (error === "Verified email required") {
//     return handleUnverifiedEmail(ctx, nextUrl);
//   }
//   if (ctx && ctx.res && ctx.res.writeHeader) {
//     ctx.res.writeHeader(302, {
//       Location: `/403?message=${encodeURI(error)}`,
//     });
//     ctx.res.end();
//   } else {
//     window.location.replace(nextUrl);
//   }
// }

// Redirects if the request isn't authorized for the requested
// resource.
function handleUnauthorized(ctx, nextUrl = null, isDemo = false) {
  // ctx.req might be undefined if called from the client
  if (!nextUrl) {
    if (ctx && ctx.req && ctx.req.url) {
      nextUrl = ctx.req.url;
    } else {
      nextUrl = window.location.pathname;
    }
  }
  if (ctx && ctx.res && ctx.res.writeHeader) {
    ctx.res.writeHeader(302, {
      Location: `${isDemo ? "/demo-redirect" : "/signin"}?next=${encodeURI(
        nextUrl
      )}`,
    });
    ctx.res.end();
  } else {
    window.location.replace(nextUrl);
  }
}

export async function postUnauthenticatedRoute(uri, _, data) {
  return makeAuthenticatedRoute(uri, null, {
    method: "post",
    data,
    skipAuth: true,
  });
}

export async function authenticatedRoute(uri, ctx, { data, query, method }) {
  return makeAuthenticatedRoute(uri + toQueryString(query), ctx, {
    method,
    data,
  });
}

export async function postAuthenticatedRoute(uri, ctx, data, opts = {}) {
  return makeAuthenticatedRoute(uri, ctx, { method: "post", data, ...opts });
}

export async function putAuthenticatedRoute(uri, ctx, data) {
  return makeAuthenticatedRoute(uri, ctx, { method: "put", data });
}

export async function patchAuthenticatedRoute(uri, ctx, data, options = {}) {
  return makeAuthenticatedRoute(uri, ctx, {
    method: "patch",
    data,
    ...options,
  });
}

export async function deleteAuthenticatedRoute(uri, ctx, data) {
  return makeAuthenticatedRoute(uri, ctx, { method: "delete", data });
}

export function getAuthenticatedRoute(_uri, ctx = {}, _data = {}, opts = {}) {
  const data = pickBy(_data, (d) => d ?? false);
  let uri = _uri;
  // map data to a list of tuples, expanding lists, so list values get expanded into the
  // form [["foo", "1"], ["foo", "2"]] ...
  const dataTuples = Object.entries(data).flatMap(([key, value]) => {
    if (Array.isArray(value)) {
      return value.map((v) => [key, v]);
    } else {
      return [[key, value]];
    }
  });

  if (data && Object.keys(data).length > 0) {
    uri = `${uri}?${new URLSearchParams(dataTuples).toString()}`;
  }

  return makeAuthenticatedRoute(uri, ctx, { method: "get", ...opts });
}

export async function makeAuthenticatedRouteForPDF(uri, ctx) {
  const url = getBackendUrl(uri);
  const firebaseToken = getTokenFromCtx(ctx);

  if (!firebaseToken) {
    logger.debug("Is unauthorized. Redirecting...", {
      ctx,
      headers: ctx?.rawHeaders,
    });
    return handleUnauthorized(ctx);
  }

  try {
    const response = await axios({
      method: "get",
      url: url,
      responseType: "arraybuffer",
      headers: {
        Authorization: `Bearer ${firebaseToken}`,
        Accept: "application/pdf",
      },
    });

    return new Blob([response.data], { type: "application/pdf" });
  } catch (error) {
    console.error(error);
    throw error;
  }
}

async function makeAuthenticatedRoute(uri, ctx, opts) {
  const {
    // If skipAuth is true, this function makes an unauthenticated request
    skipAuth,
    ...axiosOpts
  } = opts;
  const url = getBackendUrl(uri);
  if (process.env.DEBUG) {
    logger.info(`Requesting ${url}`);
  }

  const firebaseToken = getTokenFromCtx(ctx);

  if (!firebaseToken && !skipAuth) {
    logger.debug("Is unauthorized. Redirecting...", {
      ctx,
      headers: ctx?.rawHeaders,
    });
    return handleUnauthorized(ctx);
  }

  let result;
  try {
    const response = await axios({
      method: "get",
      url: getBackendUrl(uri),
      ...(!skipAuth && {
        headers: {
          Authorization: `Bearer ${firebaseToken}`,
        },
      }),
      ...axiosOpts,
    });
    result = response.data;
  } catch (error) {
    // Handle our 401s by redirecting
    console.log(getBackendUrl(uri));
    //   if (
    //     error.response &&
    //     error.response.status === 403 &&
    //     error.response.data.error.toLowerCase().includes("demo")
    //   ) {
    //     return handleUnauthorized(ctx, null, true);
    //   }
    //   if (error.response && error.response.status === 401) {
    //     return handleUnauthorized(ctx);
    //   }
    //   if (error.response && error.response.status === 403) {
    //     logger.info("Unauthorized user. Redirecting...", { ctx });
    //     return handleForbidden(ctx, error.response.data);
    //   }
    //
    //   console.error(`Error from ${url}`);
    //   console.error(error);
    //   throw error;
  }

  if (process.env.DEBUG) {
    logger.info(`Response from ${url}: ${JSON.stringify(result, null, 2)}`);
  }

  return result;
}

// Helper function to get user from request headers. Should be called from getInitialProps.
// Even though this redirects to /signin for when this is called on the server side,
// you should still check if the returned user is empty and redirect to signin
// on the client side using useEffect or some other function during render time.
export async function getMeFromCtx(ctx) {
  return getAuthenticatedRoute("/user/me", ctx);
}

const authContext = createContext();

export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

ProvideAuth.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useAuth = () => {
  return useContext(authContext);
};

function useProvideAuth() {
  const setUserAndCookie = async (rawUser = null, token = null) => {
    // If token is not null, use that one instead of whatever token
    // is in rawUser
    // See https://firebase.google.com/docs/reference/js/firebase.User#getidtoken
    //
    // Previously this was `rawUser.xa`, but that was failing for me. See this ticket:
    // https://github.com/vercel/next.js/discussions/18036#discussioncomment-109656
    // If this has any impact, we can revert to `rawUser.xa || rawUser.ya`, but I believe this is
    // the canonical solution.
    let userToken = rawUser
      ? (await rawUser.getIdToken()) || rawUser.xa || rawUser.ya
      : undefined;

    if (token) {
      userToken = token;
    }

    cookie.set("orchidAuth", userToken);

    return true;
  };

  const removeUserCookie = () => {
    cookie.remove("orchidAuth");
  };

  const signin = (email, password) => {
    email = email.trim();
    return firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(async (response) => {
        await setUserAndCookie(response.user);
        return response.user;
      });
  };

  const impersonate = async (token) => {
    const accessToken = await firebase.auth().signInWithCustomToken(token);
    await setUserAndCookie(accessToken.user);
  };

  const signinWithGoogle = () => {
    return firebase
      .auth()
      .signInWithPopup(new firebase.auth.GoogleAuthProvider())
      .then(async (response) => {
        await setUserAndCookie(response.user);
        return response.user;
      });
  };

  const signup = (email, password) => {
    email = email.trim();
    return firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then(async (response) => {
        await setUserAndCookie(response.user);
        return response.user;
      });
  };

  const signout = () => {
    return firebase
      .auth()
      .signOut()
      .then(() => {
        return removeUserCookie();
      });
  };

  const sendPasswordResetEmail = (email) => {
    email = email.trim();
    // This request doesn't use authenticated backend request
    return axios({
      method: "get",
      url: getBackendUrl(`/user/password?email=${encodeURIComponent(email)}`),
    });
  };

  const confirmPasswordReset = (password, code) => {
    const resetCode = code || getFromQueryString("oobCode");
    return firebase
      .auth()
      .confirmPasswordReset(resetCode, password)
      .then(() => {
        return true;
      });
  };

  const sendEmailConfirmation = (email) => {
    email = email.trim();
    return axios({
      method: "get",
      url: getBackendUrl(`/user/email?email=${encodeURIComponent(email)}`),
    });
  };

  const confirmEmail = (code) => {
    const resetCode = code || getFromQueryString("oobCode");
    return firebase
      .auth()
      .applyActionCode(resetCode)
      .then(() => {
        return true;
      });
  };

  const refreshToken = () => {
    logger.info("refreshing token");
    firebase.auth().currentUser?.reload();
  };

  // https://firebase.google.com/docs/reference/js/firebase.User#returns-promisestring
  // Returns Promise<string>
  const refreshBackendToken = async () => {
    const maybeNewToken = await firebase.auth().currentUser.getIdToken(true);
    // maybeNewToken is used instead of whatever token is in the currentUser
    // it SHOULD be consistent but firebase can have weird edge cases
    return setUserAndCookie(firebase.auth().currentUser, maybeNewToken);
  };

  const getFirebaseUser = () => {
    try {
      return firebase.auth().currentUser;
    } catch (err) {
      logger.info(err);
      return null;
    }
  };

  return {
    signin,
    signinWithGoogle,
    signup,
    signout,
    sendPasswordResetEmail,
    confirmPasswordReset,
    sendEmailConfirmation,
    confirmEmail,
    refreshToken,
    refreshBackendToken,
    getFirebaseUser,
    setUserAndCookie,
    impersonate,
    makeAuthenticatedRouteForPDF,
  };
}

const getFromQueryString = (key) => {
  return queryString.parse(window.location.search)[key];
};
