import { ROUTES } from "../user/routes";
import {
  hasConsents,
  healthSurveyDone,
  isValidUser,
} from "../user/userDataFormater";

export const states = {
  PURCHASED: {
    label: "Purchase",
    route: ROUTES.saliva + ROUTES.purchased,
    isVisible: true,
    isActive: true,
    onDashboard: true,
    isDone: () => false,
  },
  PERSONAL_INFO: {
    label: "Personal Info",
    route: `${ROUTES.signup}${ROUTES.step1}`,
    isVisible: false,
    isActive: false,
    onDashboard: false,
    isDone: (user) => isValidUser(user),
  },
  PARTNER_INFO: {
    label: "Invite Partner",
    route: `${ROUTES.saliva}${ROUTES.partner}`,
    isVisible: false,
    isActive: false,
    onDashboard: false,
    isDone: () => false,
  },
  CONSENT: {
    label: "Review Terms",
    route: ROUTES.saliva + ROUTES.reviewTerms,
    isVisible: true,
    isActive: true,
    onDashboard: true,
    isDone: (user) => hasConsents(user),
  },
  ACTIVATED: {
    label: "Confirm Barcodes",
    route: ROUTES.saliva + ROUTES.activation,
    isVisible: true,
    isActive: true,
    onDashboard: true,
    isDone: () => false,
  },
  HEALTH_SURVEY: {
    label: "Health Survey",
    route: ROUTES.saliva + ROUTES.healthSurvey,
    isVisible: false,
    isActive: false,
    onDashboard: false,
    isDone: (user) => healthSurveyDone(user),
  },
  RESULTS: {
    label: "Results",
    route: ROUTES.saliva + ROUTES.results,
    isVisible: true,
    isActive: false,
    onDashboard: true,
    isDone: (user, account) => {
      return account.reportReady;
    },
  },
};

export const pgtStates = {
  PURCHASED: {
    label: "Test Ordered",
    route: ROUTES.pgt + ROUTES.purchased,
    isVisible: true,
    isActive: false,
    onDashboard: true,
    isDone: (user, embryoReports) => Boolean(embryoReports),
  },
  PERSONAL_INFO: {
    label: "Personal Info",
    route: `${ROUTES.pgt}${ROUTES.step1}`,
    isVisible: false,
    isActive: true,
    onDashboard: false,
    isDone: (user, embryoReports) =>
      Boolean(embryoReports) && isValidUser(user),
  },
  CONSENT: {
    label: "Review Terms",
    route: ROUTES.pgt + ROUTES.reviewTerms,
    isVisible: true,
    isActive: true,
    onDashboard: true,
    isDone: (user, embryoReports) =>
      Boolean(embryoReports) && hasConsents(user),
  },
  HEALTH_SURVEY: {
    label: "Health Survey",
    route: ROUTES.pgt + ROUTES.healthSurvey,
    isVisible: false,
    isActive: false,
    onDashboard: false,
    isDone: (user, embryoReports) =>
      Boolean(embryoReports) && healthSurveyDone(user),
  },
  RESULTS: {
    label: "Results",
    route: ROUTES.pgt + ROUTES.results,
    isVisible: true,
    isActive: true,
    onDashboard: true,
    isDone: (user, embryoReports) => Boolean(embryoReports),
  },
};

export default states;

Object.filter = function (obj, predicate) {
  const result = {};
  let key;

  for (key in obj) {
    if (Object.keys(obj).includes(key) && !predicate(obj[key])) {
      result[key] = obj[key];
    }
  }

  return result;
};

export const numberOfSteps = Object.keys(
  Object.filter(
    states,
    (v) => Object.keys(v).includes("onDashboard") && v.onDashboard === false
  )
).length;
export const visibleStates = Object.filter(
  states,
  (v) => Object.keys(v).includes("isVisible") && v.isVisible === false
);

export const pgtVisibleSteps = Object.filter(
  pgtStates,
  (v) => Object.keys(v).includes("isVisible") && v.isVisible === false
);

export const numberOfPgtSteps = Object.keys(
  Object.filter(
    pgtStates,
    (v) => Object.keys(v).includes("onDashboard") && v.onDashboard === false
  )
).length;

export const individualStates = {
  PURCHASED: {
    label: "Purchase",
    route: ROUTES.saliva + ROUTES.purchased,
    isVisible: true,
    isActive: true,
    onDashboard: true,
    isDone: (user, order) => Boolean(order),
  },
  PERSONAL_INFO: {
    label: "Personal Info",
    route: `${ROUTES.saliva}${ROUTES.step1}`,
    isVisible: false,
    isActive: true,
    onDashboard: false,
    isDone: (user, order) => Boolean(order) && isValidUser(user),
  },
  CONSENT: {
    label: "Review Terms",
    route: ROUTES.saliva + ROUTES.reviewTerms,
    isVisible: true,
    isActive: true,
    onDashboard: true,
    isDone: (user, order) => Boolean(order) && hasConsents(user),
  },
  ACTIVATED: {
    label: "Confirm Barcodes",
    route: ROUTES.saliva + ROUTES.activation,
    isVisible: true,
    isActive: true,
    onDashboard: true,
    isDone: (user, order) => Boolean(order?.activated),
  },
  HEALTH_SURVEY: {
    label: "Health Survey",
    route: ROUTES.saliva + ROUTES.healthSurvey,
    isVisible: false,
    isActive: false,
    onDashboard: false,
    isDone: (user, order) => Boolean(order) && healthSurveyDone(user),
  },
  RESULTS: {
    label: "Results",
    route: ROUTES.saliva + ROUTES.results,
    isVisible: true,
    isActive: true,
    onDashboard: true,
    isDone: (user, order) => Boolean(order?.report?.reportReady),
  },
};

export const individualVisibleSteps = Object.filter(
  individualStates,
  (v) => Object.keys(v).includes("isVisible") && v.isVisible === false
);

export const numberOfIndividualSteps = Object.keys(
  Object.filter(
    individualStates,
    (v) => Object.keys(v).includes("onDashboard") && v.onDashboard === false
  )
).length;
